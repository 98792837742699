
export class Line{  

    constructor(hotspot, part){
        this.appManager = hotspot.appManager
        this.hotspot = hotspot
        this.part = part
        this.line 
        this.points 

        this.hotspot
        this.hotspotMesh

        this.createMaterial()
        //this.createLine()
    }

    createMaterial(){
        this.myMaterial = new BABYLON.StandardMaterial("myMaterial", this.appManager.scene);
        this.myMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1);
        this.myMaterial.emissiveColor = new BABYLON.Color3(0.35, 0.35, 0.35);
    }

    async createLine(){
        if(this.line) this.line.dispose()
       //console.log("updated Line")

        let pos = this.part.getBoundingInfo().boundingBox.centerWorld
        //this.part.showBoundingBox = true;
        let hotspotPos = this.hotspot.hotspotMesh.absolutePosition//pos.add(new BABYLON.Vector3(this.hotspot.position.x,this.hotspot.position.y,this.hotspot.position.z))
        if(hotspotPos.y<0)
            hotspotPos.y = hotspotPos.y+0.02
        else
            hotspotPos.y = hotspotPos.y-0.02
        //let x = this.part.getBoundingInfo().boundingBox.centerWorld
        //this.sphere.setAbsolutePosition(x.add(new BABYLON.Vector3(this.position.x,this.position.y+0.02,this.position.z)))
            

        this.sphere = BABYLON.MeshBuilder.CreateSphere("sphere", {}, this.appManager.scene);
        this.sphere.isVisible = false
        this.sphere.scaling = new BABYLON.Vector3(1,1,1)
        this.sphere.position = hotspotPos

        let myPoints = [
            hotspotPos,
            pos
        ]

        let tube = BABYLON.MeshBuilder.CreateTube("tube", {path: myPoints, radius: 0.001, cap: 3, updatable: true}, this.appManager.scene);
        tube.material = this.myMaterial;
        //this.tube.scaling._y = 0.9

        if(this.hotspot.part){
            //this.hotspot.hotspotMesh.parent = this.hotspot.sphere
            //this.hotspot.hotspotMesh.position = new BABYLON.Vector3(0,0,0)
            tube.setParent(this.hotspot.part); 
        }
        else
            tube.setParent(this.hotspot.root); 

        this.line = tube
        //this.line.setEnabled(false)
        //this.line.scaling._x = 0.9
        //this.line.scaling._y = 0.9
        //this.line.scaling._z = 0.9
    }

    updatePositions(){
        this.line.setParent(null);
        let pos = this.part.getBoundingInfo().boundingBox.centerWorld
        let hotspotPos = pos.add(new BABYLON.Vector3(this.hotspot.position.x,this.hotspot.position.y,this.hotspot.position.z))

        let testPoints = [
            hotspotPos,
            pos
        ]

        
        this.line = BABYLON.MeshBuilder.CreateTube("tube", {
            path: testPoints,
            instance: this.line,
        });

        if(this.hotspot.part){
            this.line.setParent(this.hotspot.part); 
        }
        else
            this.line.setParent(this.hotspot.root); 
    }

}