const BABYLON = require('babylonjs');
const GUI = require('babylonjs-gui');

import './main.css'
import 'babylonjs-loaders'
import "babylonjs-gui"
import { AppManager } from "./Scripts/Managers/appManager"


/* if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js').then(registration => {
        console.log('SW registered: ', registration);
      }).catch(registrationError => {
        console.log('SW registration failed: ', registrationError);
      });
    });
  } */

let engine, scene, appManager

function renderTour(){
    engine = new BABYLON.Engine(document.getElementById("renderCanvas"),true, null, true)
    
    const id = getProductId()
    if(id){
      fetchFromWP(id)
      /* fetch("./content/json/"+id+".json")
        .then(response => response.json())
        .then(data => startRendering(data))  */
    }
    else{
      fetch("./content/json/14494.json")
        .then(response => response.json())
        .then(data => startRendering(data)) 
    }
    
    //fetchFromWP("14494")
}

async function startRendering(data){
  console.log(data)
    scene = await createScene(data)
    engine.runRenderLoop(function (){
        scene.render() 
    }) 
}

var createScene = async function (data) {
    var scene = new BABYLON.Scene(engine)
    var colour = data.acf.background_colour
    
    scene.clearColor = BABYLON.Color3.FromHexString(colour);//new BABYLON.Color3(30/255,30/255,30/255)
    console.log(scene.clearColor)
    appManager = new AppManager(engine, scene, data)
    return scene
}

function fetchFromWP(id){
  fetch("https://dromone.com/wp-json/wp/v2/companion-product/"+id+"?_fields=acf&acf_format=standard")
      .then(response => response.json())
      .then(data => startRendering(data)) 
}

function getProductId(){
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get('id')
  console.log("id",id)

  return id
}

//https://dromone.com/wp-json/wp/v2/companion-product/

window.addEventListener("resize", function () {
    engine.resize()
  })
  
window.addEventListener('DOMContentLoaded', (event) => {
    renderTour()
})

/* if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then(() => { console.log('Service Worker Registered'); });
} */
