import {MDCSwitch} from '@material/switch';
import {MDCDialog} from '@material/dialog';

export class TextPopup{

    constructor(appManager){
        this.appManager = appManager

        this.initialise()
    }

    initialise(){
        this.TextPopup = new MDCDialog(document.getElementById('TextPopup'))
        this.TextPopup.scrimClickAction=''
        this.TextPopup.listen('MDCDialog:opened',()=> this.TextPopup.scrimClickAction='close')
        this.TextPopup.listen('MDCDialog:closed',()=> this.TextPopup.scrimClickAction='')
        this.textPopupContainer = document.getElementById('textPopupContainer')
        this.textPopupTitleContainer = document.getElementById('textPopupTitleContainer')
        this.textPopupImage = document.getElementById('TextPopupImage')

        this.textClose = document.getElementById('close-btn-text')
        this.textClose.onclick = () => this.TextPopup.close()
    }

    loadText(uri, image){
        if(image != null)this.textPopupImage.src = image
        this.textPopupTitleContainer.innerHTML = " "
        this.textPopupContainer.innerHTML = uri +"<br>"+'<a href="https://dromone.com/contact/'+'">'+"https://dromone.com/contact/"+'</a><br>'
        this.openTextPopup()
    }
    openTextPopup(){
        this.TextPopup.scrimClickAction=''
        this.TextPopup.open()
    }
    closeTextPopup(){
        
    }
}