import { Scene } from "../Objects/scene"

export class SceneManager{  

    constructor(appManager){
        this.appManager = appManager
        this.sceneData = appManager.data.scenes
        this.currentSceneId
        this.scenes = []

        this.initialise()
    }

    initialise(){
        var count = 0
        this.sceneData.forEach(obj => {
            this.scenes.push(new Scene(this.appManager, obj, count))
            count++
        })
    }
    
    loadScene(id){
        if(id == "h"){
            this.appManager.homeScreen.displayHomeScreen()
        }else{
            this.currentSceneId = id
            let scene = this.getSceneById(id)
            this.hideLastScene()
            scene.displayScene()
        }
        
    }

    hideLastScene(){
        if(this.appManager.sceneHistory.length < 1 ||this.appManager.sceneHistory[this.appManager.sceneHistory.length-1]=="h") return
        let lastScene = this.getSceneById(this.appManager.sceneHistory[this.appManager.sceneHistory.length-1])
        lastScene.setHotspotVisibility(false)
    }

    goBackScene(){
        this.hideLastScene()
        this.appManager.sceneHistory.pop()
        let id = this.appManager.sceneHistory.pop()
        this.currentSceneId = id
        if(id == "h"){
            this.appManager.homeScreen.displayHomeScreen()
        }else{
            let scene = this.getSceneById(id)
            scene.displayScene()
        }   
        if(this.appManager.data.jump_camera_to_hotspots)
            this.appManager.cameraManager.resetCameraTarget()
    }

    getSceneById(id){
        let result = null
        /* this.scenes.forEach(scene => {
            if(scene.id == id) result = scene
        }) */
        return this.scenes[parseInt(id)-1]//result
    }
}