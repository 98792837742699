
export class NFCReader{
    constructor(appManager, scene){
        this.appManager = appManager
        this.scene = scene
        this.nfcValues = []

        this.initialise()
    }

    async initialise(){

    }

    async readNFC(){
        try {
            const ndef = new NDEFReader();
            await ndef.scan();
            console.log("> Scan started");
        
            ndef.addEventListener("readingerror", () => {
                console.log("Argh! Cannot read data from the NFC tag. Try another one?");
            });
        
            ndef.addEventListener("reading", ({ message, serialNumber }) => {
                const decoder = new TextDecoder();
                for (const record of message.records) {
                    const obj = JSON.parse(decoder.decode(record.data));

                    for (const hotspot of this.scene.hotspots) {
                        if(hotspot.title.includes("registered"))
                            hotspot.updateHotspotNFCValue(obj.registered)
                        else if(hotspot.title.includes("changeovers"))
                            hotspot.updateHotspotNFCValue(obj.changeovers)
                    }
                    //this.nfcValues[0].text = "changeovers: "+obj.changeovers
                    //this.nfcValues[1].text = "date-registered: "+obj.registered
                }
            });
        } catch (error) {
            console.log("Argh! " + error);
        }
    };

    async writeNFC(){
        try {
            const ndef = new NDEFReader();
            await ndef.write(
                `{
                    "id": 1,
                    "changeovers": "231",
                    "date-registered": "09/10/21"
                }`
            );
            console.log("> Message written");
          } catch (error) {
            console.log("Argh! " + error);
          }
    };

    updateSceneNFCValues(){

    }
}