export class RotatePrompt{  

    constructor(appManager){
        this.appManager = appManager
        this.modelRoot = appManager.modelManager.root
        this.htmlPrompt = document.getElementById("rotatePrompt")
        
        //this.initialise()
    }
    initialise(){
        this.start()
        this.appManager.cameraManager.camera.onViewMatrixChangedObservable.add(() => this.stop());
    }

    stop(){
        this.htmlPrompt.classList.add("hide")
        this.appManager.cameraManager.camera.onViewMatrixChangedObservable.clear();
        if(this.tweenIcon){
            this.tweenIcon.kill()
            this.tweenMesh.kill()
        }
    }

    start(){
        var angle = new BABYLON.Vector3(0, 0, 0)
        this.modelRoot.setPivotPoint(new BABYLON.Vector3(0, 0, 0));
        this.htmlPrompt.classList.remove("hide")
        //this.appManager.productManager.hotspotManager.toggleHotspots()

        this.tweenIcon = gsap.fromTo( "#rotatePrompt",{ x: -35, ease: "sine.inOut" }, { x: 35, duration: 2.5, repeat:-1, yoyo:true, ease: "sine.inOut" });
        this.tweenMesh = gsap.fromTo( angle,{ x: this.modelRoot.rotation.y, ease: "sine.inOut" }, { x: this.modelRoot.rotation.y-0.5, duration: 2.5, repeat:-1, yoyo:true, ease: "sine.inOut" , onUpdate : () =>{
                this.modelRoot.rotation.y = angle.x
                this.appManager.productManager.hotspotManager.hotspots.forEach(hotspot => {
                    hotspot.hotspotMesh.position = hotspot.sphere.absolutePosition
                    hotspot.childrenWidgets.forEach(hotspotWidget => {
                        if(hotspotWidget.sphere)
                            hotspotWidget.hotspotMesh.position = hotspotWidget.sphere.absolutePosition
                    })
                })
            }
        });
    }
}