import { Hotspot } from "../Objects/hotspot";
import {MDCRipple} from '@material/ripple';

export class HotspotManager{  

    constructor(appManager){
        this.hotspotData = appManager.data.hotspots
        this.appManager = appManager
        
        this.hotspots = []
        this.hotspotWidgets = []
        this.trackHotspots = false
        this.currentExp = null
        //this.hotspotParent = new BABYLON.TransformNode("hotspotParent");
        //this.hotspotData = hotspotData//this.appManager.data.hotspots
/*         experiences.forEach((exp) => {
            if(exp.hotspots){
                this.createHotspots(exp.hotspots, exp.title)
            }
        })

        this.backButton = document.getElementById("back-btn");
        const fabRipple = new MDCRipple(this.backButton);
        this.backButton.onclick = () => this.resetHotspots() */
        this.createHotspots()
    }

    createHotspots(){
        this.hotspotData.forEach((obj) => {
            this.hotspots.push(new Hotspot(this, obj))
        })
        this.appManager.modelManager.currentModel.setStartAnimationFrame()

        /* window.setTimeout(() => {
        }, 100); */
    }

    /* createHotspots(hotspotData, expTitle){
        hotspotData.forEach((obj) => {
            if(obj.type == "hotspot"){
                this.hotspots.push(new Hotspot(this, obj, expTitle))
            }
        })
    } */

    getHotspotById(id){
        var result
        this.hotspots.forEach((hotspot) => {
            if(hotspot.unique_id == id){
                return result = hotspot
            }
        })
        return result
    }

    showHotspotByExpName(experience){
        this.currentExp = experience
        this.hotspots.forEach((hotspot) => {
            if(hotspot.expTitle == experience)
                hotspot.setHotspotVisible(true)
        })
    }

    toggleHotspots(){
        this.hotspots.forEach((hotspot) => {
            if(hotspot.hotspotMesh.isEnabled())
                hotspot.setHotspotVisible(false)
            else
                hotspot.setHotspotVisible(true)
        })
    }
    toggleHotspotWidgets(){
        var parts = []
        this.hotspotWidgets.forEach((hotspotWidget) => {
            if(hotspotWidget.hotspotMesh.isEnabled()){
                hotspotWidget.setHotspotVisible(false)
                parts = ["null"]
            }
            else{
                hotspotWidget.setHotspotVisible(true)
                parts.push(hotspotWidget.part)
            }
        })
        this.appManager.modelTransparent.highlightParts(parts)
        

    }
    hideHotspots(){
        this.hotspots.forEach((hotspot) => {
            if(hotspot.hotspotMesh.isEnabled())
                hotspot.setVisibility(false)
        })
    }
    hideHotspotWidgets(){
        var parts = []
        this.hotspotWidgets.forEach((hotspotWidget) => {
            if(hotspotWidget.hotspotMesh.isEnabled()){
                hotspotWidget.setHotspotVisible(false)
                parts = ["null"]
            }
        })
        this.appManager.modelTransparent.highlightParts(parts)
    }
    resetHotspots(){
        this.appManager.productManager.animationControls.resetAnimation(false)
        this.appManager.productManager.models.animationGroups[0].goToFrame(0)
        this.appManager.productManager.models.animationGroups[0].play()
        this.appManager.productManager.models.animationGroups[0].pause()
        this.hotspotWidgets.forEach((hotspotWidget) => {
            if(hotspotWidget.hotspotMesh.isEnabled()){
                hotspotWidget.setHotspotVisible(false)
            }
        })
        this.appManager.modelTransparent.highlightParts(["null"])

        /* this.hotspots.forEach((hotspot) => {
            if(!hotspot.hotspotMesh.isEnabled())
                hotspot.setHotspotVisible(true)
        }) */
        this.showHotspotByExpName(this.currentExp)
        //document.getElementById('overlaidInstructions').classList.remove("hide")
        this.backButton.classList.add("hide")
        this.appManager.cameraManager.resetCameraTarget()
    }

    drawHotspotLines(){
        this.hotspots.forEach((hotspot) => {
            hotspot.setVisibility(true)
        })
    }
}