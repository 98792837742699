export class HomeScreen{

    constructor(appManager){
        this.appManager = appManager

        this.initialise()
    }

    initialise(){
        this.topBarElement = document.querySelector(".mdc-top-app-bar")
        this.homeScreen = document.getElementById('homeScreen')
        this.homeScreenBackground = document.getElementById('homeScreenBackground')
        this.overlaidInstructions = document.getElementById('overlaidInstructions')
        this.homeScreenText = document.getElementById('homeScreenText')
        this.prompt = document.getElementById('overlaidInstructionsText')
        this.experienceTitle = document.getElementById('experienceTitle')
        this.menuLogo = document.getElementById("menuLogo")
        this.menuLogo.src = this.appManager.data.app_logo
        this.homeScreenText.innerHTML = this.appManager.data.description

        this.setUpButtons()
    }

    setUpButtons(){
        this.homescreenButton = document.getElementById('buttonOption')
        this.experiences = this.appManager.data.menu_buttons

        this.experiences.forEach(experience => {
            let element = this.homescreenButton.cloneNode(true)
            element.classList.remove("hide")
            this.homeScreen.appendChild(element)
            if(experience.colour != undefined)
                element.style.backgroundColor = experience.colour;
            element.childNodes.forEach((child) => {
                if(child.innerHTML === "title"){
                    child.innerHTML = experience.displayed_text
                }
                else if(child.innerHTML === "icon"){
                    child.innerHTML = experience.icon
                }
            })
            element.onclick = () => this.loadExperience(experience);
        })

        const buttonHome = document.getElementById('buttonHome')
        buttonHome.onclick = () => this.displayHomeScreen();
    }

    loadExperience(experience){
        if(experience.type == "link to scene")
            this.loadScene(experience.linked_content.scene_id);
        else if(experience.type == "link to url")
            window.open(experience.linked_content.link_to_url, '_blank');
        else if(experience.type == "link to content"){
            console.log("Show content",experience.linked_content.content_to_display)//window.open(experience.url, '_blank');
            this.appManager.mediaPlayer.load(experience.linked_content.content_to_display, "")
        }
    }

    displayHomeScreen(){
        this.appManager.sceneHistory = ["h"]
        this.appManager.sceneManager.currentSceneId = "h"
        let lastScene = this.appManager.sceneHistory[this.appManager.sceneHistory.length-1]
        this.appManager.sceneHistory[this.appManager.sceneHistory.length-1]
        if(lastScene != "h" && lastScene != undefined) {
            let scene = this.appManager.sceneManager.getSceneById(lastScene)
            scene.setHotspotVisibility(false)
        }
        this.homeScreen.classList.remove("hide")
        this.homeScreenBackground.classList.remove("hide")
        this.topBarElement.classList.add("hide")
        this.overlaidInstructions.classList.add("hide")
        this.appManager.cameraManager.zoomCameraTo(0.8)
        this.appManager.cameraManager.camera.useAutoRotationBehavior = true;
        this.appManager.transparentController.setAllTransparent(false)
        this.appManager.modelManager.currentModel.animationController.animationGroups[0].stop()
        this.appManager.modelManager.currentModel.animationController.animationGroups[0]
            .start(false, 1, this.appManager.data.starting_animation_frame, this.appManager.data.starting_animation_frame);
        this.appManager.sceneHistory.push("h")
        this.appManager.hotspotManager.hideHotspots()
        if(this.appManager.data.jump_camera_to_hotspots)
            this.appManager.cameraManager.resetCameraTarget()
    }

    loadScene(sceneId){
        this.appManager.sceneManager.loadScene(sceneId)
        this.hideHomeScreen()
    }

    hideHomeScreen(){
        this.homeScreen.classList.add("hide")
        this.homeScreenBackground.classList.add("hide")
        this.topBarElement.classList.remove("hide")
        this.appManager.cameraManager.camera.useAutoRotationBehavior = false;
    }
}