import {MDCSwitch} from '@material/switch';
import {MDCDialog} from '@material/dialog';

export class VideoPopup{

    constructor(appManager){
        this.appManager = appManager
        this.currentContentURI

        this.initialise()
    }

    initialise(){
        this.VideoPopup = new MDCDialog(document.getElementById('VideoPopup'))
        this.VideoPopup.scrimClickAction=''
        this.VideoPopup.listen('MDCDialog:opened',()=> this.VideoPopup.scrimClickAction='close')
        this.VideoPopup.listen('MDCDialog:closed',()=> this.VideoPopup.scrimClickAction='')
        this.videoElement = document.getElementById('videoElement')
        this.videoSourceElement = document.getElementById('videoSourceElement')

        this.videoClose = document.getElementById('close-btn-video')
        this.videoClose.onclick = () => this.VideoPopup.close()
    }

    loadVideo(uri){
        /* let buffer1
        fetch(uri)
        .then(response => response.arrayBuffer())
        .then(buffer => {
            buffer1 = buffer
        })
        let blob = new Blob([buffer1], { type: 'video/mp4'})
        console.log(buffer1) */

        fetch(uri)
        .then(res => res.blob()) // Gets the response and returns it as a blob
        .then(blob => {
          console.log(blob)
          document.getElementById('videoSourceElement').src = URL.createObjectURL(blob);
          document.getElementById('videoElement').load() 
          document.getElementById('videoElement').pause()
        });

        this.currentContentURI = uri
        //this.videoSourceElement.src = uri//URL.createObjectURL(blob)
        //this.videoElement.load() 
        //this.videoElement.pause()
        this.openVideoPopup()
    }

    openVideoPopup(){
        this.VideoPopup.scrimClickAction=''
        this.VideoPopup.open()
        this.VideoPopup.listen('MDCDialog:closed',()=> this.videoElement.pause())
    }
    closeVideoPopup(){
        
    }
}