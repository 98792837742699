//const GUI = require('babylonjs-gui');
import * as GUI from "babylonjs-gui"
import { Line } from "../Objects/line";
import { NFCReader } from "../Utilities/nfcReader";

export class Hotspot{  

    constructor(hotspotManager, hotspotData){
        this.appManager = hotspotManager.appManager
        this.root = new BABYLON.TransformNode("hotspotRoot");
        this.unique_id = hotspotData.unique_id
        this.type = hotspotData.settings.type
        this.title = hotspotData.settings.title
        this.style = hotspotData.settings.shape
        this.partName = hotspotData.line_settings.part_id || ""
        this.drawLine = hotspotData.line_settings.draw_line
        this.position = new BABYLON.Vector3(parseFloat(hotspotData.position.x),parseFloat(hotspotData.position.y,10),parseFloat(hotspotData.position.z))
        this.line_settings = hotspotData.line_settings
        this.linked_content = hotspotData.linked_content

        //this.hotspotData = hotspotData
        //this.partLines = hotspotData.partLines

        this.hotspot
        this.hotspotMesh
        this.line

        this.checkForPart()
    }

    async checkForPart(){
        if(this.partName != "") 
            this.part = await this.appManager.modelManager.currentModel.getPartRefByName(this.partName)
        this.createHotspot()
        if(this.type == "nfcScanner"){
            this.nfcReader = new NFCReader(this.appManager, this.scene)
        }
    }

    createHotspot(){
        if(this.style == "square") 
            this.hotspot = this.createSquareHotspotGUI();
        else if(this.style == "circle")
            this.hotspot = this.createCircleHotspotGUI();
        else
            this.hotspot = this.createCircleHotspotGUI();
        
        this.hotspotMesh = this.createHotspotMesh(this.hotspot);
        this.animateWidgetScaleTo(1);
        this.createLine()

        this.hotspot.onPointerEnterObservable.add(() => {
            //this.animateWidgetScaleTo(1.2);
            //this.scaleDownOtherHotspots();
        })
        this.hotspot.onPointerOutObservable.add(() => {
            //this.animateWidgetScaleTo(1);
        })
        this.hotspot.onPointerClickObservable.add(() => {
            //this.animateWidgetScaleTo(1);
            this.triggerEvent();
            //document.getElementById('overlaidInstructions').classList.add("hide")
            //this.hotspotManager.backButton.classList.remove("hide")
            //this.appManager.rotatePrompt.stop()
            //if(this.appManager.xrManager.xr.baseExperience.state != BABYLON.WebXRState.IN_XR)
                //this.appManager.cameraManager.camera.target = this.childrenWidgets[0].partsRef[0]
        })
        this.hotspotMesh.setEnabled(false)
    }

    createCircleHotspotGUI() {
        let hotspot = new GUI.Ellipse("hotspot", "");
        hotspot.width = "750px";
        hotspot.height = "750px";
        hotspot.paddingBottomInPixels = 10;
        hotspot.paddingTopInPixels = 10;
        hotspot.paddingLeftInPixels = 10;
        hotspot.paddingRightInPixels = 10;
        hotspot.color = "#FFFFFFFF"
        hotspot.background = this.appManager.data.theme_colour
        hotspot.thickness = 30;
        hotspot.shadowColor = "#000000FF"
        hotspot.shadowBlur = 97.00;
        hotspot.clipChildren = false;
        hotspot.clipContent = false;

        let text = new GUI.TextBlock("text",this.title);
        text.fontSizeInPixels = 150;
        text.resizeToFit = true
        text.color = "white"; 
        hotspot.addControl(text);
        this.text = text

        return hotspot
    }
    createSquareHotspotGUI() {
        let hotspot = new GUI.Rectangle("hotspot", "");
        let width = (100 * this.title.length) + 150
        hotspot.width = width+"px";
        hotspot.height = "500px";
        hotspot.paddingBottomInPixels = 40;
        hotspot.paddingTopInPixels = 40;
        hotspot.paddingLeftInPixels = 40;
        hotspot.paddingRightInPixels = 40;
        hotspot.color = "#FFFFFFFF"
        hotspot.background = this.appManager.data.theme_colour
        hotspot.thickness = 20;
        hotspot.cornerRadius = 50;
        hotspot.shadowColor = "#000000FF"
        hotspot.shadowBlur = 97.00;
        hotspot.clipChildren = false;
        hotspot.clipContent = false;

        let text = new GUI.TextBlock("text",this.title);
        text.fontSizeInPixels = 150;
        text.color = "white"; 
        text.width = 0.9;
        text.height = 1;
        text.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER
        hotspot.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER
        this.text = text
        if(this.nfcValue)
            this.appManager.nfcReader.nfcValues.push(text)

        if(this.title.length < 3)
            hotspot.cornerRadius = 250;

        hotspot.addControl(text);

        return hotspot
    }

    createHotspotMesh(hotspotGUI){
        this.root.position = new BABYLON.Vector3(this.position.x,this.position.y,this.position.z)
        let hotspotMesh
        if(this.style == "square") 
            hotspotMesh = BABYLON.MeshBuilder.CreatePlane("hotspotMesh", {width: parseInt(hotspotGUI.width)/10000, height:0.05}, this.appManager.scene);
        else
            hotspotMesh = BABYLON.MeshBuilder.CreatePlane("hotspotMesh", {width:parseInt(hotspotGUI.width)/10000, height:parseInt(hotspotGUI.width)/10000}, this.appManager.scene);
        hotspotMesh.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
        //hotspotMesh.scaling = new BABYLON.Vector3(1,1,1)
        hotspotMesh.scaling = new BABYLON.Vector3(0.1,0.1,0.1)

        if(this.part){
            hotspotMesh.parent = this.part;
            hotspotMesh.position = new BABYLON.Vector3(this.position.x,-this.position.y-0.02,this.position.z)

            this.sphere = BABYLON.MeshBuilder.CreateSphere("sphere", {}, this.appManager.scene);
            this.sphere.scaling = new BABYLON.Vector3(1,1,1)
            this.sphere.parent = this.part
            this.sphere.isVisible = false
            //this.sphere.setAbsolutePosition(new BABYLON.Vector3(this.position.x,this.position.y+0.02,this.position.z))
            let x = this.part.getBoundingInfo().boundingBox.centerWorld
            this.sphere.setAbsolutePosition(x.add(new BABYLON.Vector3(this.position.x,this.position.y+0.02,this.position.z)))
            //this.sphere.setAbsolutePosition(this.part.getBoundingInfo().boundingBox.centerWorld.add(new BABYLON.Vector3(this.position.x,this.position.y+0.02,this.position.z)))
        }
        else
            hotspotMesh.parent = this.root; 

        let hotspotADT = GUI.AdvancedDynamicTexture.CreateForMesh(hotspotMesh, parseInt(hotspotGUI.width), parseInt(hotspotGUI.height));

        hotspotADT.renderScale = 1;
        hotspotADT.addControl(hotspotGUI);
        if(this.part)
            hotspotADT.uAng = Math.PI

        return hotspotMesh
    }

    createLine(){
        if(this.drawLine != true) return
        this.line = new Line(this, this.part)
    }

    setVisibility(value){
        this.hotspotMesh.setEnabled(value)
        if(!this.line)return
        if(!value){
            if(!this.line.line)return
            this.line.line.dispose()
        }
        else{
            this.updateLine()
            //this.sphere.position = new BABYLON.Vector3(0,0,0)
        }
        
    }
    updateLine(){
        if(this.line){
            //this.line.updatePositions()
            //this.line.line.setEnabled(value)
            this.line.createLine()
        }
    }

    updatePosition(){
        this.hotspotMesh.position = new BABYLON.Vector3(this.position.x,-this.position.y,this.position.z)
    }
    

    animateWidgetScaleTo(scale){
        let ease = new BABYLON.CubicEase();
        ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT);

        BABYLON.Animation.CreateAndStartAnimation('WidgetScaleX', this.hotspotMesh, 'scaling.x', 60, 10, this.hotspotMesh.scaling.x, scale, 0, ease);
        BABYLON.Animation.CreateAndStartAnimation('WidgetScaleY', this.hotspotMesh, 'scaling.y', 60, 10, this.hotspotMesh.scaling.y, scale, 0, ease);
    }

    triggerEvent(){
        if(this.type == "anim"){
            this.appManager.productManager.models.animationGroups[0].play()
            this.appManager.productManager.models.animationGroups[0].goToFrame(this.animEnd)
            this.appManager.productManager.models.animationGroups[0].pause()
        }
        else if(this.type === "file"){
            if(this.appManager.data.jump_camera_to_hotspots)
                this.appManager.cameraManager.camera.target = this.position
            if(this.linked_content.heading != undefined)
                this.appManager.mediaPlayer.load(this.linked_content.file, this.linked_content.heading)
            else
                this.appManager.mediaPlayer.load(this.linked_content.file, "")
        }
        else if(this.type === "url"){
            window.open(this.linked_content.url, '_blank');
        }
        else if(this.type === "text"){
            this.appManager.mediaPlayer.textPopup.loadText(this.hotspotData.text,this.hotspotData.textImage)
        }
        else if(this.type == "scene"){
            this.appManager.sceneManager.loadScene(this.linked_content.scene_id)
                if(this.appManager.data.jump_camera_to_hotspots)
            this.appManager.cameraManager.resetCameraTarget()
        }
        else if(this.type == "nfcScanner")
            this.nfcReader.readNFC()
        //this.hotspotManager.hideHotspots()
    }

    scaleDownOtherHotspots(){
        this.hotspotManager.hotspots.forEach((hotspot) => {
            if(hotspot != this)
                hotspot.animateWidgetScaleTo(0.14);
        })
    }

    updateHotspotNFCValue(value){
        this.value = value
        this.text = this.title + this.value
    }
}