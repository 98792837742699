import {MDCDialog} from '@material/dialog';
import { TextPopup } from "../htmlElements/textPopup"
import { ImagePopup } from "../htmlElements/imagePopup"
import { VideoPopup } from "../htmlElements/videoPopup"
import { IframePopup } from "../htmlElements/iframePopup"

export class MediaPlayer{

    constructor(appManager){
        this.appManager = appManager
        this.currentContentURI = ""

        this.initialise()
    }

    initialise(){
        this.textPopup = new TextPopup(this.appManager)
        this.imagePopup = new ImagePopup(this.appManager)
        this.videoPopup = new VideoPopup(this.appManager)
        this.iframePopup = new IframePopup(this.appManager)
        
        //this.iframePopup = document.getElementById('iframePopup')  
        //this.pdfDownloadLink = document.getElementById('pdfDownloadLink')        
    }

    load(uri, title){
        if(uri == null || uri == undefined){
            console.log("Not a supported file type.")
            return 
        }
        this.currentContentURI = uri
        if(uri.includes(".glb"))
            this.loadModel(uri)
        else if(uri.includes(".mp4"))
            this.videoPopup.loadVideo(uri)
        else if(uri.includes(".pdf"))
            this.iframePopup.loadIframe(uri)//this.loadDocument(uri)
        else if(uri.includes(".png") || uri.includes(".jpg") || uri.includes(".gif"))
            this.imagePopup.loadImage(uri, title)
        else if(uri.includes(".anim"))
            this.loadAnimation(content)
        else
            console.log("Not a supported file type.")
    }

    loadModel(uri){
        this.appManager.productManager.loadProductModel(uri, false); 
        this.appManager.productManager.disposeModel() 
    }
    loadDocument(uri){
        this.pdfDownloadLink.href = uri
        this.PDFPopup.open()
    }
    loadAnimation(content){
        this.appManager.productManager.animationControls.resetAnimation(this.appManager.productManager.models.animationGroups[0], content.animationStart ,content.animationEnd); 
    }
}