import {MDCSwitch} from '@material/switch';
import {MDCDialog} from '@material/dialog';

export class ImagePopup{

    constructor(appManager){
        this.appManager = appManager
        this.currentContentURI

        this.initialise()
    }

    initialise(){
        this.ImagePopup = new MDCDialog(document.getElementById('ImagePopup'))
        this.imagePopupTitle = document.getElementById('imagePopupTitle')
        this.imgPopup = document.getElementById('imgPopup')
        this.imgFullscreen = document.getElementById('fullscreen-btn-image')
        this.imgClose = document.getElementById('close-btn-image')
        this.ImagePopup.scrimClickAction=''
        this.ImagePopup.listen('MDCDialog:opened',()=> this.ImagePopup.scrimClickAction='close')
        this.ImagePopup.listen('MDCDialog:closed',()=> this.ImagePopup.scrimClickAction='')

        this.imgFullscreen.onclick = () => window.open(this.currentContentURI,'Image','width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');
        this.imgClose.onclick = () => this.ImagePopup.close()
    }

    loadImage(uri, title){
        if(title != null)this.imagePopupTitle.innerHTML = title
        else this.imagePopupTitle.innerHTML = ""

        this.currentContentURI = uri
        this.imgPopup.src = uri
        this.openImagePopup()
    }
    openImagePopup(){
        this.ImagePopup.scrimClickAction=''
        this.ImagePopup.open()
    }
    closeImagePopup(){
        
    }
}